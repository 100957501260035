import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getStudent } from "../../store/redux/features/student/studentSlice";
// import Search from "../../ui/card/search/Search";
// import { Spinner } from "../../loader/Loader";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import {AiOutlineEye} from "react-icons/ai"
import { Link } from "react-router-dom";
import { shortenText } from "../utils/index";
import ReactPaginate from "react-paginate";
import './viewplayer.css'
import StudentList from "../students/studentlist/StudentList";

const ViewPlayers = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  //Begin Paginate:
  const itemsPerPage = 10;
  const { students, isLoading, isError, message } = useSelector(
    (state) => state.student.students
  );
//End Paginate

  return (
    <section id="players">
      <div className="container product-list">
      <div>
         <StudentList  students={students} isLoading={isLoading}/>
    </div>
   
      </div>
    </section>
  );
};

export default ViewPlayers;
