import React, { useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import Logo from '../../assets/Chess Championship-02.png'
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <nav className="navbar ">
      <div className="logo">
        {/* <FaTimes color="#fff" size={33} /> */}
        <Link to='/'><img src={Logo}/></Link>
        {/* <p className="logo-text">
          <span>Logo</span>
        </p> */}
      </div>
      <menu>
        <ul
          className="nav-links"
          id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}
        >
          <li>
            <Link to='/'>Home</Link>
            
          </li>
          <li>
            <a href="#features">About</a>
          </li>
          
          <li>
            <Link to='/gallery'>Gallery</Link>
          </li>
          <li>
            <Link to='/'>Events</Link>
          </li>

          <li>
            <Link to="/register" className="btn btn-dark">
              Register
            </Link>
          </li>
          <li className="nav-btn">
            <Button text={"Contact Us"} btnClass={"btn-dark"} href={"#faq"} />
          </li>
        </ul>
      </menu>
      <div className="menu-icons" onClick={toggleMenu}>
        {showMenu ? (
          <RiCloseLine color="#1f93ff" size={30} />
        ) : (
          <AiOutlineBars color="#1f93ff" size={27} />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
