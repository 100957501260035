import { useEffect, useState } from "react";
import "./Slider.css";
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa";
import  { sliderData } from "./SliderData";
import { Link } from "react-router-dom";
// import {useNavigate} from "react-router-dom"
const Slider = () => {
  const slideLength = sliderData.length;

  const [currentSlide, setCurrentSlide] = useState(0);
  // const  navigate= useNavigate()


  let slideInterval;
  let intervalTime = 5000

  useEffect(()=>{
    setCurrentSlide(0)
  },[])

  useEffect(()=>{
    function auto(){
        slideInterval = setInterval(nextSlide,intervalTime)
    };
    auto();
    return ()=>{
        clearInterval(slideInterval)
    }
  },[currentSlide,slideInterval]);


  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  return (
    <div className="slider">
      <FaRegArrowAltCircleLeft className="arrow prev" onClick={prevSlide} />
      <FaRegArrowAltCircleRight className="arrow next" onClick={nextSlide} />

      {sliderData.map((slide, index) => {
        return (
          <div
            key={index}
            className={index === currentSlide ? "slide current" : "slide"}>
            {index === currentSlide && (
              <>
                <img src={slide.image} alt="slide" />
                <div className="content">
                  <span className="span1"></span>
                  <span className="span2"></span>
                  <span className="span3"></span>
                  <span className="span4"></span>
                  <h2>{slide.heading}</h2>
                  <p>{slide.desc}</p>
                  <hr />
                  {/* <button className="--btn --btn-primary" ><Link to='/register'>Register your school</Link></button> */}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Slider;
