import axios from 'axios';
import { toast } from "react-toastify";

const BACKEND_URL = process.env.REACT_BACKEND_URL;
const API_URL = `https://nigeriachess.onrender.com/api/students`
//
// 'http://localhost:5000/api/students'
const createStudent = async (student) => {
  try {
    const response = await axios.post(API_URL, student);
    
    return response.data;
    
  } catch (error) {
    console.error('Error creating student:', error);
    toast.error("Error registering players", error.message);
    throw new Error('Error registering players');
  }
};

// Get all students
const getStudents = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch students:', error);
    toast.error("Failed to fetch students");
    return null;
  }
};

// Delete student
const deleteStudent = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete student:', error);
    toast.error("Failed to delete student");
    return null;
  }
};

// Update student
const updateStudent = async (id, formData) => {
  try {
    const response = await axios.patch(`${API_URL}/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error('Failed to update student:', error);
    toast.error("Failed to update student");
    return null;
  }
};

// Get single student
const getStudent = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch student:', error);
    toast.error("Failed to fetch student");
    return null;
  }
};

const studentService = {
  createStudent,
  getStudents,
  deleteStudent,
  updateStudent,
  getStudent
};

export default studentService;
