import ReactPlayer from 'react-player';
import './Video.css';

const Video = () => {
  return (
    <div className="video-container">
    <ReactPlayer
      className="react-player"
      url='https://youtube.com/shorts/kSTyCl9dEO4?feature=share'
      controls
      width="100%"
      height="100%"
    />
  </div>
  )
}

export default Video