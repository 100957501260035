import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoading, selectStudent } from '../../../store/redux/features/student/studentSlice';
import { getStudents } from '../../../store/redux/features/student/studentSlice';
import { FILTER_STUDENTS, selectFilteredStudents } from '../../../store/redux/features/student/filterSlice';
import Loader from '../../loader/Loader';
import { Pagination } from 'antd';

const StudentList = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const filteredStudents = useSelector(selectFilteredStudents);
  const dispatch = useDispatch();
  const students = useSelector(selectStudent);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    // Function to fetch data from local storage
    const fetchDataFromLocalStorage = () => {
      const storedStudents = localStorage.getItem('students');
      if (storedStudents) {
        const parsedStudents = JSON.parse(storedStudents);
        setCurrentItems(parsedStudents);
        setPageCount(Math.ceil(parsedStudents.length / itemsPerPage));
      }
    };

    const fetchStudents = async () => {
      try {
        const studentsData = await dispatch(getStudents()).unwrap();
        localStorage.setItem('students', JSON.stringify(studentsData));
        setCurrentItems(studentsData);
        setPageCount(Math.ceil(studentsData.length / itemsPerPage));
  
        // Call fetchDataFromLocalStorage after setting currentItems
        fetchDataFromLocalStorage();
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };
  
    // Fetch students from the server
    fetchStudents();
  }, [dispatch]);
  

  useEffect(() => {
    if (students.length > 0) {
      setCurrentItems(students);
      setPageCount(Math.ceil(students.length / itemsPerPage));
    }
  }, [students]);
  

  useEffect(() => {
    if (filteredStudents.length > 0) {
      setCurrentItems(filteredStudents);
      setPageCount(Math.ceil(filteredStudents.length / itemsPerPage));
    }
  }, [filteredStudents]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  
  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && currentItems.length === 0) {
    return <div>No data available.</div>;
  }

  return (
    <div className="student-list">
      <hr />
      <div className="table">
        <div className="--flex-between --flex-dir-column">
          <span>
            {/* <h3>Students {totalPlayers}</h3> */}
          </span>
        </div>
        <>
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Name</th>
                <th>School</th>
                <th>category</th>
                <th>Fide</th>
              </tr>
            </thead>
            <tbody>
              {currentItems
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((student, index) => {
                  const { _id, schoolName, ageCategory, players } = student;
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>
                        {Array.isArray(players) && players.map((player, playerIndex) => (
                          <div key={playerIndex}>{player.name}</div>
                        ))}
                      </td>
                      <td>{schoolName}</td>
                      <td>{ageCategory}</td>
                      <td>
                        {Array.isArray(players) && players.map((player, playerIndex) => (
                          <div key={playerIndex}>{player.fideId}</div>
                        ))}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Pagination
            defaultCurrent={1}
            total={currentItems.length}
            pageSize={itemsPerPage}
            onChange={handlePageClick}
          />
        </>
      </div>
    </div>
  );
};

export default StudentList;
