
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import studentService from './studentService';
import { toast } from 'react-toastify';

const initialState = {
student:null,
students:[],
isError:false,
isSuccess:false,
isLoading:false,
message:"",
totalStoreValue:0,
course:[]

};
//create new student 
export const createStudent = createAsyncThunk(
  "students/create",
  async (formData, thunkAPI) => {
    try {
      const student = await studentService.createStudent(formData);
      toast.success('Registration successful see you at Tournament')
      return student;
    } catch (error) {
    console.log(error)
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all students
//get all students
export const getStudents = createAsyncThunk(
  'students/getAll',
  async (_, thunkAPI) => {
    try {
      // Fetch students from the service
      const students = await studentService.getStudents();
      localStorage.setItem('students', JSON.stringify(students));

      // Return the fetched students
      return students;
    } catch (error) {
      // Handle errors
      console.error('Error fetching students:', error);
      throw error; // Rethrow the error to be handled by Redux Toolkit
    }
  }
);

  

//delete a student

export const deleteStudent = createAsyncThunk(
    "students/delete",
    async (id, thunkAPI) => {
      try {
        await studentService.deleteStudent(id);
        thunkAPI.dispatch(updateLocalStorage(thunkAPI.getState().students.filter(student => student.id !== id)));
        return id;
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

// update student 


export const updateStudent = createAsyncThunk(
    "students/updateStudent",
    async ({ id, formData }, thunkAPI) => {
      try {
        const updatedStudent = await studentService.updateStudent(id, formData);
        const updatedStudents = thunkAPI.getState().students.map(student =>
          student.id === id ? updatedStudent : student
        );
        thunkAPI.dispatch(updateLocalStorage(updatedStudents));
        return updatedStudent;
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

//get a student

export  const  getStudent = createAsyncThunk(
    "students/getStudent",
    async(id,thunkAPI) => {
        try {
            return await studentService.getStudent(id)
        } catch (error) {
            const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message)
        }
    }
)

const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    CALC_STORE_VALUE(state, action){
        const students = action.payload
        const array = []
        students.map((item) => {
            const {price } = item 
            const studentValue = price  * students.length
            return array.push(studentValue)
        })
        const totalValue = array.reduce((a,b) =>{
            return a+b
        },0)
        state.totalStoreValue = totalValue
    },
    CALC_OWING(state,action){
        const students = action.payload
        const array = []
        students.map((item) => {
            const {price,mainprice } = item 
             
            const owedamount = mainprice - price 
            
            
            return array.push(owedamount)
        });
        // let count = 0;
        // array.forEach((number) =>{
        //     if (number < mainprice){
        //         count +=1
        //     }
           
        // })
        // state.owedamount =count
    },
    CALC_COURSE(state, action) {
        const students = action.payload
        const array = []
        students.map((item) => {
            const {course } = item 
           
            return array.push(course)
        });
        const uniqueCourse = [...new Set(array)]
        state.course = uniqueCourse;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createStudent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.students.push(action.payload);
        toast.success('Student added successfully');
      })
      .addCase(createStudent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(deleteStudent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.students = state.students.filter(student => student.id !== action.payload);
        toast.success('Student deleted successfully');
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(updateStudent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.students = state.students.map(student =>
          student.id === action.payload.id ? action.payload : student
        );
        toast.success('Student updated successfully');
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
  },
});

export const {CALC_STORE_VALUE,CALC_COURSE,CALC_OWING} = studentSlice.actions
export const selectIsLoading = (state) => state.student.isLoading;
export const selectStudent = (state) => state.student.students
export const selectTotalStoreValue = (state) => state.student.totalStoreValue;
export const selectOwedAmount = (state) => state.student.owedamount;
export const selectCourse = (state) => state.student.course;

export default studentSlice.reducer

const updateLocalStorage = (students) => {
    localStorage.setItem('students', JSON.stringify(students));
  }