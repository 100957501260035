// https://i.ibb.co/g4RDXbZ/phone2.jpg
import img1 from '../../assets/winner primary.jpeg'
import img2 from '../../assets/winner secondary.jpeg'
import img3 from '../../assets/winner tertiary.jpeg'




export const sliderData = [
    {
      image: img1,
      heading: " 1st Position Primary School",
      desc: "MARVELVINE MONTESSORI SCHOOL",
    },
    {
      image: img2,
      heading: " 1st Position Secondary School",
      desc: "DAYSPRING SCHOOL IBADAN",
    
    },
    {
      image: img3,
      heading: " 1st Position Tertiary Instituition",
      desc: "UNIVERSITY OF LAGOS",
     
    },
    // {
    //   image: img4
      
    // },
   
  ];