import React from "react";
import "./Footer.css";
import logo from "../../assets/Chess Championship-03.jpg";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFax,
  FaEnvelope,
  FaGlobe,
  FaTimes
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section id="footer">
      <div className="container footer">
        <div className="footer-box">
          <h4>Useful Links</h4>
          <div className="footer-links">
          <Link href="/register"> REGISTER</Link>
            <a href="#">  TOURNAMENT REGULATIONS</a>
            <a href="#"> REGISTERED PLAYERS</a>
            <a href="#">ABOUT US</a>
          </div>
        </div>
       
        <div className="footer-box">
          <h4>Contact Us</h4>
          <div className="footer-contact u-text-small">
            
           
            
            <p>
              <FaEnvelope /> &nbsp; Email: nationalschoolsteamchess@gmail.com
            </p>
            <p>
              <FaGlobe /> &nbsp; Website: www.https://www.nationalschoolchess.com
            </p>
          </div>
        </div>
        <div className="footer-box">
        <p className="logo-text">
         <img src={logo}/>
        </p>
          
       
          <p className="u-text-small">&copy; Copyright 2024. nationalschoolschess.com</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
