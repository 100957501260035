
import ReactPlayer from 'react-player';

import './gallery.css';
import Img1 from '../../assets/_DSC6110.jpg'
import Img2 from '../../assets/_DSC5754.jpg'
import Img3 from '../../assets/_DSC5772.jpg'
import Img4 from '../../assets/_DSC5791.jpg'
import Img5 from '../../assets/_DSC5792.jpg'
import Img6 from '../../assets/_DSC5797.jpg'
import Img7 from '../../assets/_DSC5798.jpg'
import Img8 from '../../assets/_DSC5801.jpg'
import Img9 from '../../assets/_DSC5839.jpg'
import Img10 from '../../assets/_DSC5841.jpg'
import Img11 from '../../assets/_DSC5844.jpg'
import Img12 from '../../assets/_DSC5848.jpg'
import Img13 from '../../assets/_DSC5856.jpg'
import Img14 from '../../assets/_DSC5869.jpg'
import Img15 from '../../assets/_DSC5871.jpg'
import Img16 from '../../assets/_DSC5892.jpg'
import Img17 from '../../assets/_DSC5902.jpg'
import Img18 from '../../assets/_DSC5910.jpg'
import Img19 from '../../assets/_DSC5913.jpg'
import Img20 from '../../assets/_DSC5917.jpg'
import Img21 from '../../assets/_DSC5922.jpg'
import Img22 from '../../assets/_DSC5928.jpg'
import Img23 from '../../assets/_DSC6035.jpg'
import Img24 from '../../assets/_DSC6042.jpg'
import Img25 from '../../assets/_DSC6074.jpg'
import Img26 from '../../assets/_DSC6085.jpg'
import Img27 from '../../assets/_DSC6087.jpg'
import Img28 from '../../assets/_DSC6092.jpg'
import Img29 from '../../assets/_DSC5852.jpg'

import Img30 from '../../assets/_DSC6099.jpg'
import Img31 from '../../assets/_DSC6139.jpg'
// import Vid1 from 'https://youtube.com/shorts/kSTyCl9dEO4?feature=share'
const Gallery = () => {
  // Sample array of image URLs
  const images = [Img1,
   Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9,Img10, Img11,Img12,Img29,Img30,Img31];
    // const videos = [Vid1]
    const images2 = [Img13,Img14,Img15,Img16,Img17,Img18,Img19,Img20,Img21,Img22,Img23,Img24,Img25,Img26,Img27,Img28];
  return (
    <div className='container main'>
        <h4>-- Gallery --</h4>
        
    <div className="gallery ">
       
       {images.map((imageUrl, index) => (
         <div key={index} className="gallery-item">
           <img src={imageUrl} alt={`Image ${index}`} loading="lazy" />
         </div>
       ))}
     </div>
     {/* {
videos.map((vid) =>(
<iframe src={vid}></iframe>
))
     }
     */}
      <div className="video-container">
    <ReactPlayer
      className="react-player"
      url='https://youtube.com/shorts/kSTyCl9dEO4?feature=share'
      controls
      width="100%"
      height="100%"
    />
  </div>
     {/* videos tag */}
     <div className="gallery">
     {images2.map((imageUrl, index) => (
         <div key={index} className="gallery-item">
           <img src={imageUrl} alt={`Image ${index}`} loading="lazy" />
         </div>
       ))}
    </div>
    <div className="video-container">
    <ReactPlayer
      className="react-player"
      url='https://youtu.be/XXxoZ61wLxI'
      controls
      width="100%"
      height="100%"
    />
    
  </div>
    
    </div>
    
  );
};

export default Gallery;
