import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import {
  Navbar,
  Header,
  Features,
  Download,
  Subscribe,
  Footer,
} from "./components";
import Build from "./components/Build/Build";
import ViewPlayers from "./components/viewProducts/ViewPlayer";
import Register from "./components/Register/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Gallery from "./components/grid/Gallery";
import Tournament from "./components/Tournament/Tournament";
import Video from "./videos/Video";

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <header className="header-bg">
                  <Header />
                </header>
                
                <Features />
                <Download />
                <Video/>
                <ViewPlayers />
                <Subscribe />
                {/* <Build/> */}
              </div>
            }
          />
          <Route path="/register" element={<Tournament/>} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
