// Register.js
import React, { useState } from 'react';
import './register.css';
import image1 from '../../assets/ad5.jpg';
import StudentForm from '../students/studentform/StudentForm';
import { createStudent } from '../../store/redux/features/student/studentSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from 'react';
import { toast } from 'react-toastify';


const initialState = {
    schoolName: "",
    state: "",
    address: "",
    ageCategory: "",
    tutorInfo: "",
    tutorPhone: "",
    principalName: "",
    phone: "",
    // schoolEmail: "",
    ecobankAccount: "",
    image: null,
};


const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [student, setStudent] = useState(initialState);
  const [studentImage, setStudentImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedState, setSelectedState] = useState(""); // Add selectedState state variable

  const handleStateChange = (event) => {
    const selectedStateValue = event.target.value;
    setSelectedState(selectedStateValue);
  
    // Manually set the value of the state field in formik's values object
    formik.setFieldValue('state', selectedStateValue);
  };
const schema = {
      schoolName: Yup.string().required("School name is Required"),
      address: Yup.string().required("Address is Required"),
      ageCategory: Yup.string().required("please choose an age category"),
      tutorInfo: Yup.string().required("Tutor Info is Required"),
      tutorPhone: Yup.number(),
      //.required("Tutor Phone number is Required")
      principalName: Yup.string().required("Principal Name is Required"),
      phone: Yup.number(),
    //   schoolEmail: Yup.string().email().required("School Email is Required"),
    //   schoolEcobankAccount: Yup.number(),
      //.required("School Ecobank Account is Required"),.required("School Ecobank Account is Required"),
      state: Yup.string().required('Please select an option'),
      image: Yup.mixed().required('Please add proof of payment')
    };

let playerValidationSchema = {};
let playerValidationValue = {};
[...Array(5)].forEach((_, index) => {
  const playerIndex = index + 1;
  playerValidationValue[`name${index}`] = ""
//   playerValidationValue[`dateOfBirth${index}`] = ""
  playerValidationValue[`fideId${index}`] = ""
  
  playerValidationSchema[`name${index}`] = Yup.string().required(`Player ${playerIndex} Name is Required`);
//   playerValidationSchema[`dateOfBirth${index}`] = Yup.date().required(`Player ${playerIndex} Date of Birth is Required`);
  playerValidationSchema[`fideId${index}`] = Yup.number()
//   .required(`Player ${playerIndex} FIDE ID is Required`);
});
const handleImageChange = (event) => {
  formik.setFieldValue('image', event.currentTarget.files[0]);
  setImagePreview(URL.createObjectURL(event.currentTarget.files[0])); // Set image preview
};


const formik = useFormik({
    initialValues: {...initialState, ...playerValidationValue},
    validationSchema: Yup.object({
    ...schema,
    ...playerValidationSchema,
    }),
    onSubmit: async (values) => {
      // console.log(values);
        await dispatch(createStudent(values));
        // window.location.reload();
        
            navigate('/');
           
         
        },
  }
  );


  

  useEffect(() => {
    if (formik.errors) {
      Object.keys(formik.errors).forEach((key) => {
        if (formik.touched[key] && Boolean(formik.errors[key])){
          toast.error(formik.errors[key]);
        }
        
      });
    }
  }, [formik.errors, formik.touched]);
  

  
  return (
    <div id='register'>
      <div className='reg-one'>
        <div className='reg-text'>
          {/* Your text content */}
        </div>
        <StudentForm
  values={formik.values}
  blur={formik.handleBlur}
  student={student}
  studentImage={studentImage}
  imagePreview={imagePreview}
  handleInputChange={formik.handleChange}
  handleImageChange={handleImageChange}
  saveStudent={formik.handleSubmit}
  selectedCourse={selectedCourse}
  selectedState={selectedState} // Pass selectedState as a prop
  handleStateChange={handleStateChange}/>

      </div>
      <div className='reg-two'><img src={image1} alt="image1" /></div>
    </div>
  );
}

export default Register;