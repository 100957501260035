import React, { useEffect } from "react";
import "./Download.css";
import { IconContext } from "react-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import ECO from '../../assets/ecobank-white-01-1024x1024.png'


const Download = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="download">
      <div className="container download" data-aos="fade-up">
        <h2>Our sponsor</h2>
        <p className="u-text-small">
        Sponsor & Partners
        </p>
        <IconContext.Provider value={{ size: "15" }}>
         
           <div className="social-icons">
           <div className="sponsor-image">
           <img src={ECO}/>
          </div>
          {/* <div className="sponsor-image">
           <img src={NCF}/>
          </div>
          <div className="sponsor-image">
           <img src={FIDE}/>
          </div>
          <div className="sponsor-image">
           <img src={RITE}/>
          </div>
         
          
          <div className="sponsor-image">
           <img src={BURGER}/>
          </div>
          <div className="sponsor-image">
           <img src={CHESSACA}/>
          </div> */}
          
        </div>
        </IconContext.Provider>
      </div>
      {/* <div className="video-container">
          <iframe
            width="560" // Set the initial width
            height="315" // Set the initial height
            src="https://www.youtube.com/embed/VIDEO_ID_HERE" // Replace VIDEO_ID_HERE with the actual YouTube video ID
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div> */}
    </section>
  );
};

export default Download;
