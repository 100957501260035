import React, { useEffect, useState } from "react";
import "./Header.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from '../slider/Slider'
import { FaChess } from "react-icons/fa";
import { Link } from "react-router-dom";



const Header = () => {
  const [countdownMessage, setCountdownMessage] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    const interval = setInterval(() => {
      const currentDate = new Date();
      const targetDate = new Date('May 24, 2024');
      const difference = targetDate.getTime() - currentDate.getTime();

      if (difference <= 0) {
        setCountdownMessage('Tournament started');
        clearInterval(interval);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      if (currentDate >= targetDate && currentDate <= new Date('May 26, 2024')) {
        setCountdownMessage('Tournament started');
        clearInterval(interval);
      } else if (currentDate > new Date('May 26, 2024')) {
        setCountdownMessage('Tournament ended');
        clearInterval(interval);
      } else {
        setCountdownMessage(`Days to Tournament ${days} : ${hours}: ${minutes}: ${seconds}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  
  return (
    <section id="header">
      <div className="container header">
        <div className="header-left" data-aos="fade-right">
          <h1>
            <span>Ecobank National Schools Team Chess Championship 2024</span>
            <span>Date: 24th - 28th May, 2024</span>
            <span> Venue: Pan African Centre, Ecobank HQ, Victoria Island, Lagos State.
</span>

          </h1>
          <p className="u-text-small">
             Tournament has ended 
          </p>
          
          <p>see you next time</p>
          <div className="header-cta">
            <Link to='/register'><Button text={"Register"} btnClass={"btn-dark"} href={""} /></Link>
            <Button text={"Find Out More"} btnClass={"btn-light"} href={"#features"} />
          </div>
        </div>
        <div className="header-right" >
        <Slider/>
        </div>
      </div>
      <div className="floating-icon">
        <a href="#features">
          <FaChess color="#fff" size={25} className="mouse" />
         
        </a>
      </div>
      
    </section>
  );
};

export default Header;
