import React from 'react'
import './tournament.css' 

const Tournament = () => {
  return (
    <div className='Tournament'>
       <div>
       <h1>Tournament has ended</h1>
        <a href='/'>click here to see winners table</a>
     <div className='Tournament-video'>
     <video autoPlay loop src='https://videos.pexels.com/video-files/6689571/6689571-uhd_3840_2160_25fps.mp4'></video>
     </div>
    
       </div>
       </div>
  )
}

export default Tournament