import React, { useEffect } from "react";
import "./Feature.css";
import { FaAccessibleIcon } from "react-icons/fa";
import { BsHexagon } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";

const Feature = ({ icon, heading, text, result }) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="feature" data-aos="fade-up">
      <div className="feature-icon">

        <div className="inner-icon">{icon}</div>
      </div>

      <div className="feature-text">
        <h3>{heading}</h3>
        <p className="u-text-small">{text}</p>
        <p className="result">{result}</p>
      </div>
      
    </div>
  );
};

export default Feature;
