import React, { useEffect } from "react";
import "./Subscribe.css";

import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa";

import AOS from "aos";
import "aos/dist/aos.css";

const Subscribe = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="subscribe">
      <div className="container subscribe" data-aos="fade-up">
        <h2>Contact Us!</h2>
       <div className="form">
       <form>
          {/* <div className="form-control"> */}
            <input type="text" placeholder="Enter Your Email..." />
            <button><a className="buttonlink" href="mailto:nationalschoolsteamchess@gmail.com">Subscribe</a></button>
          {/* </div> */}
        </form>
       </div>
        <div className="social-icons">
          
          {/* <div className="social-icon">
            <FaFacebookF />
          </div> */}
          <a href="https://twitter.com/natschlchess_ng" target='_blank'> <div className="social-icon">
           <FaTwitter />
          </div></a>
          <a href="https://www.instagram.com/natschlchess.ng">  <div className="social-icon">
           <FaInstagram />
          </div></a>
          <a  href="https://api.whatsapp.com/send?phone=23408133697123" target='_blank'><div className="social-icon">
            <FaWhatsapp  />
          </div></a>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
