import React, { useEffect } from "react";
import "./Features.css";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import phoneFeatures from "../../assets/ad8.jpg";
import Feature from "./Feature";
import { FeatureList } from "./data";
import PDF from '../../assets/National School Team Chess Championship 2024 Regulations^.pdf'
import AOS from "aos";
import "aos/dist/aos.css";
import { FaChessBoard, FaRegArrowAltCircleDown } from "react-icons/fa";
import Button from "../UI/Button/Button";

const Features = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="features">
      <div className="container features">
        <div className="title" >
        
          <FaChessBoard  color="#1f93ff" size={30} />
          <h2>Overview</h2>
          <p className="u-text-small">
          The National Schools Team Chess Championships is the flagship Nigeria Chess Federation (NCF)
competition among the teams of general educational institutions (hereinafter referred to as 
“NSTCC”). The event shall be governed by the General Regulations for FIDE Competitions.
NSTCC is organized under the aegis of the Nigeria Chess Federation.
Teams of chess academies, chess clubs, chess departments of junior physical training clubs, 
specialized sports schools, Olympic reserve schools, or those that combine players from more 
than one general educational institution are not allowed to participate</p>
        </div>
        <div className="features-content">
          <div className="features-left" data-aos="fade-right">
            <img src={phoneFeatures} alt="phone" />
          </div>
          <div className="features-right" >
            {FeatureList.map((feature) => (
              <Feature
              
                key={feature.id}
                icon={feature.icon}
                heading={feature.heading}
                text={feature.text}
                result={feature.result}
              />
            ))}
          </div>
         
        </div>
      
   <a  href={PDF} download className='btn-light'>download Tournament Regulations <FaRegArrowAltCircleDown color="#fff" size={20} /></a>
      </div>
    </section>
  );
};

export default Features;
